<template>
    <div>
    <v-card flat class="pa-3 mt-2">
      <v-cart-text>
        <TablaReporte
          @GetEditar="GetEditar"
          @GetBtn="UpDateIndActive"
          :acciones="['EDITAR']"
          :nombreReporte="nombreLista"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
          :otrasAcciones="otrasAcciones"
        ></TablaReporte>
      </v-cart-text>
    </v-card>
  </div>
</template>
<script>
import { ref, context, watch, onBeforeMount } from '@vue/composition-api'
import store from '@/store'
import TablaReporte from '@/components/TablaReporte.vue'
import CuentasPorPagarServices from '@/api/servicios/CuentasPorCobrarServices'
import { mdiCheck, mdiCancel } from '@mdi/js'
export default {
    components: {
        TablaReporte,
    },
    setup(props, context){
        const search = ref('')
        const datos = ref([])
        const cargandoDatos = ref(false)
        const otrasAcciones = [
            { nombre: 'Activar', icon: mdiCheck, emit: 'GetBtn', color: 'success', ifValor: {columna: "indActivo"  , valor:false} }, 
            { nombre: 'Desactivar', icon: mdiCancel, emit: 'GetBtn', color: 'error', ifValor: {columna: "indActivo"  , valor:true} }
        ]
        const nombreLista = ref(`Lista de cuentas por cobrar`)
        const cabecera = ref([
            { text: 'Producto', value: 'producto.nombre', visible: true },
            { text: 'Proveedor', value: 'proveedor.nombre', visible: true },
            { text: 'Cantidad', value: 'cantidad', format: { date: true }, visible: true },
            { text: 'Monto', value: 'monto', format: { date: true }, visible: true },
            { text: 'Fecha', value: 'fecha', format: { date: true }, visible: true },
            {
                text: 'Ind activo',
                value: 'indActivo',
                visible: true,
                format: { icon: true, icons: [mdiCheck, mdiCancel], valores: [true, false], color: ['success', 'error'] },
            },
            { text: 'Accion', value: 'accion', visible: true },
        ])
        const GetEditar = item => {
            console.log(item)
            context.emit('GetEditar', { ...item })
        }
        return{
            search,
            datos,
            cargandoDatos,
            otrasAcciones,
            nombreLista,
            cabecera,
            GetEditar,
        }
    }
}
</script>