<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-subtitle>
            Cuentas por cobrar
        </v-card-subtitle>
        <v-form ref="form" lazy-validation>
            <v-card-text class="d-flex">
                <v-row>
                    <v-col cols="12" lg="4">
                        <SelectProducto ref="SelectProductoRef" :clearable="true" :dense="true"
                            :outlined="true" :icon="false" :rules="reglas.producto" v-model="datos.idProducto">
                        </SelectProducto>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <SelectProveedor ref="SelectProveedorRef" :clearable="true" :dense="true"
                            :outlined="true" :icon="false" :rules="reglas.proveedor" v-model="datos.idProveedor"></SelectProveedor>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field :rules="reglas.cantidad" v-model="datos.cantidad"
                            label="Cantidad *" :disabled="disabledInput" required outlined dense hide-details="auto"
                            >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-text-field :rules="reglas.monto" v-model="datos.monto"
                            label="Monto *" :disabled="disabledInput" required outlined dense hide-details="auto"
                            >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <date-picker
                            v-model="datos.fecha"
                            :rules="reglas.fecha"
                            :label="`Fecha *`"
                            :dense="true"
                            :outlined="true"
                        ></date-picker>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-btn color="secondary" class="me-3  " :disabled="guardando" @click="limpiar()" small> Limpiar
                        </v-btn>
                        <v-btn color="primary" class="me-3" :loading="guardando" small @click="Guardar()"> Guardar </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import SelectProducto from '@/views/sistema/producto/catalogo/producto/componentes/SelectProducto.vue'
import SelectProveedor from '../../../../sistema/producto/catalogo/proveedor/componentes/Select.vue'
import DatePicker from '@/components/DatePicker.vue'
import CuentasPorCobrarServices from '@/api/servicios/CuentasPorCobrarServices'
export default {
    components: {
        SelectProducto,
        SelectProveedor,
        DatePicker
    },
    setup(props, context){
        const guardando = ref(false);
        const form = ref(null);
        const SelectProductoRef =  ref(null)
        const SelectProveedorRef = ref(null)
        const reglas = {
            prducto: [

                v => !!v || "Producto es requerido"
            ],
            proveedor: [

                v => !!v || "Proveedor es requerido"
            ],
            cantidad: [

                v => !!v || "Cantidad es requerido"
            ],
            monto: [

                v => !!v || "Monto es requerido"
            ],
            fecha: [

                v => !!v || "Fecha es requerido"
            ],
        }
        const datosInit = {
            "id": -1,
            "idProducto": -1,
            "idProveedor": null,
            "cantidad": null,
            "monto": '',
            "fecha": '',
        }
        const datos = ref(JSON.parse(JSON.stringify(datosInit)));
        const CargarEditar = (item) => {
            goTo(0)
        }
        const validarForm = () => {
            let val = form.value?.validate()
            return val
        }
        const Guardar = () => {

        }

        const ActualizarLista = item => {
            console.log(item)
            context.emit('GetActualizarLista', { ...item })

        }
        onBeforeMount(() => {
    
        })
        const limpiar = () => {
            datos.value = JSON.parse(JSON.stringify(datosInit));
            form.value.resetValidation();
        }
        return{
            guardando,
            form,
            datos,
            reglas,
            SelectProductoRef,
            SelectProveedorRef,
            limpiar,
            CargarEditar,
            validarForm,
            Guardar,
            ActualizarLista
        }
    }
}
</script>